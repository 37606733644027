import { DepartmentsEnum, SortOrder } from '@moonpig/web-explore-types-graphql'
import { useEffect, useMemo } from 'react'
import { createViewItemListGAEvent } from '@moonpig/web-shared-products'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { Region } from '@moonpig/web-core-types'
import {
  GALLERY_PAGE_SIZE,
  GALLERY_PAGE_TYPE,
  SEARCH_PAGE_TYPE,
} from '../constants'
import { SelectedSuggestion } from '../components/types'
import { SearchProduct } from '../services'

export const useTrackLoadedProducts: (props: {
  region: Region
  products: SearchProduct[]
  searchTerm: string
  totalCount: number
  loadedMoreProducts: boolean
  lastLoadedPage: number
  pageType: string
  pageTitle: string
  facets: {
    group: string
    facetKey: string
  }[]
  selectedSuggestion?: SelectedSuggestion
  department: DepartmentsEnum[]
  sortOrder: SortOrder
}) => void = ({
  products,
  totalCount,
  searchTerm,
  lastLoadedPage,
  pageType,
  pageTitle,
  facets,
  department,
  selectedSuggestion,
}) => {
  const facetsWithSuggestion = useMemo(
    () => [
      ...facets,
      ...(selectedSuggestion
        ? [
            {
              group: selectedSuggestion.group,
              facetKey: selectedSuggestion.facetKey,
            },
          ]
        : []),
    ],
    [selectedSuggestion, facets],
  )

  useEffect(() => {
    const offset = lastLoadedPage * GALLERY_PAGE_SIZE - GALLERY_PAGE_SIZE
    const loadedProducts = products.slice(offset, offset + GALLERY_PAGE_SIZE)
    if (loadedProducts.length > 0) {
      const number_of_results = totalCount
      const product_department =
        (department.length && department[0].toLocaleLowerCase()) || 'n/a'
      const product_filter =
        (facetsWithSuggestion.length &&
          facetsWithSuggestion[
            facetsWithSuggestion.length - 1
          ].facetKey.toLocaleLowerCase()) ||
        'n/a'
      const product_term = searchTerm.toLocaleLowerCase() || 'n/a'
      const page_title = pageTitle.toLocaleLowerCase()

      if (pageType === GALLERY_PAGE_TYPE) {
        const gallery_data = {
          number_of_results,
          product_department,
          product_filter,
          product_term,
          page_title,
        }

        const label = `${pageType} | ${product_department} | ${product_filter} | ${product_term} | ${page_title}`
        const event = createViewItemListGAEvent({
          products: loadedProducts,
          label,
          gallery_data,
        })
        trackGAEvent(event)
      }

      if (pageType === SEARCH_PAGE_TYPE) {
        const search_data = {
          index: '',
          input_type: 'keyword',
          number_of_results,
          product_department,
          search_term: product_term,
          page_title,
        }
        const label = `${pageType} | ${product_department} | ${product_term} | ${page_title}`
        const event = createViewItemListGAEvent({
          products: loadedProducts,
          label,
          search_data,
        })
        trackGAEvent(event)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])
}
