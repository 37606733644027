import React, { FC } from 'react'
import throttle from 'lodash.throttle'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import { ScrollToTop } from './ScrollToTop'
import { RecentlyViewed } from '../RecentlyViewed'

type FixedBottomBarProps = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  pageTitle: string
}

const StyledFixedWrapper = styled.div`
  z-index: 2;
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-bottom: 0px;
  pointer-events: none;

  ${breakpointUp('md')} {
    width: 100%;
    transform: none;
  }
`

export const FixedBottomBar: FC<FixedBottomBarProps> = ({
  isOpen,
  onClose,
  onOpen,
  pageTitle,
}) => {
  const [showScrollToTop, setShowScrollToTop] = React.useState<boolean>(false)

  React.useEffect(() => {
    let prevScrollDepth = window.scrollY

    const scrollEvent = throttle(() => {
      const currentScrollDepth = window.scrollY

      setShowScrollToTop(
        currentScrollDepth > 2000 && prevScrollDepth > currentScrollDepth,
      )

      prevScrollDepth = currentScrollDepth
    }, 100)

    window.addEventListener('scroll', scrollEvent)

    return () => window.removeEventListener('scroll', scrollEvent)
  }, [])

  return (
    <StyledFixedWrapper data-testid="web-gallery-fixed-bottom-bar">
      <ScrollToTop showScrollToTop={showScrollToTop} />
      <RecentlyViewed
        pageTitle={pageTitle}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
      />
    </StyledFixedWrapper>
  )
}
