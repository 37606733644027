import {
  topLevelFacetFragment,
  midLevelFacetFragment,
  bottomLevelFacetFragment,
  headerFragment as FilterHeaderGqlType,
  filterToggleFragment as ToggleFilterGqlType,
  filterInvertedToggleFragment as InvertedToggleFilterGqlType,
} from '../filters/__generated__/createLoadFilters'
import {
  FilterHeader,
  FilterCategory,
  FilterItem,
  ApplicableFilter,
} from './services'

type FilterFacetGqlType = Overwrite<
  topLevelFacetFragment | midLevelFacetFragment | bottomLevelFacetFragment,
  {
    children?:
      | topLevelFacetFragment['children']
      | midLevelFacetFragment['children']
      | null
  }
>

export type FilterItemGqlType =
  | FilterFacetGqlType
  | ToggleFilterGqlType
  | InvertedToggleFilterGqlType
  | FilterHeaderGqlType

const allFiltersInCategorySelected = (filters: FilterItem[]) => {
  return filters.every(
    filter => filter.__typename === 'Filter' && filter.isSelected,
  )
}

const mapGqlFilterFacetToDomain = (
  gqlFilter:
    | FilterFacetGqlType
    | ToggleFilterGqlType
    | InvertedToggleFilterGqlType,
): FilterCategory | ApplicableFilter => {
  if (
    gqlFilter.__typename === 'FilterFacet' &&
    gqlFilter.children &&
    gqlFilter.children.length
  ) {
    const children = gqlFilter.children.map(child =>
      mapGqlFilterItemToDomain(child as FilterItemGqlType),
    )
    if (gqlFilter.all) {
      children.unshift({
        __typename: 'AllFilter',
        id: `all-${gqlFilter.facetKey}`,
        parent: gqlFilter.facetKey,
        isSelected: allFiltersInCategorySelected(children),
        label: gqlFilter.label,
      })
    }
    return {
      __typename: 'FilterCategory',
      id: gqlFilter.facetKey,
      parent: gqlFilter.group,
      count: gqlFilter.count,
      label: gqlFilter.label,
      nbaScore: gqlFilter.nbaScore || undefined,
      isQuickFilter: !!gqlFilter.isQuickFilter,
      children,
    }
  }

  if (gqlFilter.__typename === 'ToggleFilter') {
    return {
      __typename: 'FilterToggle',
      id: gqlFilter.facetKey,
      parent: gqlFilter.group,
      isSelected: gqlFilter.isSelected,
      label: gqlFilter.label,
      ...(gqlFilter.icon && { icon: gqlFilter.icon }),
    }
  }

  if (gqlFilter.__typename === 'InvertedToggleFilter') {
    return {
      __typename: 'FilterInvertedToggle',
      id: gqlFilter.facetKey,
      parent: gqlFilter.group,
      isSelected: gqlFilter.isSelected,
      label: gqlFilter.label,
      ...(gqlFilter.icon && /* istanbul ignore next */ {
        icon: gqlFilter.icon,
      }),
    }
  }

  return {
    __typename: 'Filter',
    id: gqlFilter.facetKey,
    parent: gqlFilter.group,
    count: gqlFilter.count,
    isSelected: gqlFilter.isSelected,
    label: gqlFilter.label,
    nbaScore: gqlFilter.nbaScore || undefined,
  }
}

const mapGqlFilterHeaderToDomain = (
  gqlFacet: FilterHeaderGqlType,
): FilterHeader => ({
  __typename: gqlFacet.__typename,
  label: gqlFacet.name,
})

export const mapGqlFilterItemToDomain = (
  gqlFacet: FilterItemGqlType,
): ApplicableFilter | FilterCategory | FilterHeader => {
  return gqlFacet.__typename === 'FilterFacet' ||
    gqlFacet.__typename === 'ToggleFilter' ||
    gqlFacet.__typename === 'InvertedToggleFilter'
    ? mapGqlFilterFacetToDomain(gqlFacet)
    : mapGqlFilterHeaderToDomain(gqlFacet)
}
