import React, { FC, ReactNode } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

const StyledFooter = styled.div`
  ${s({
    bgcolor: 'colorBackground01',
    borderTop: '1px solid',
    borderColor: 'colorBorder03',
  })}
`

type Props = {
  footerRef: React.RefObject<HTMLDivElement>
  footer?: ReactNode
}

export const DrawerFooter: FC<Props> = ({ footerRef, footer }) => {
  return footer ? <StyledFooter ref={footerRef}>{footer}</StyledFooter> : null
}
