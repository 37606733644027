import { gql } from '@moonpig/web-core-graphql'
import {
  LoadFiltersRequest,
  LoadFiltersRequestContext,
  LoadFiltersResponse,
} from '../types/services'
import { AppContext } from '../types'
import {
  GetFiltersServiceQuery,
  GetFiltersServiceQueryVariables,
} from './__generated__/createLoadFilters'
import { runGraphQLQuery } from '../helpers/runGraphQLQuery'
import { FilterItemGqlType, mapGqlFilterItemToDomain } from '../types/mappings'
import { transformFiltersToGroupedFilters } from '../helpers/groupFilters'

const GetFiltersServiceGQL = gql`
  query GetFiltersService(
    $searchTerm: String!
    $department: [DepartmentsEnum!]!
    $experimentValues: String
    $groupedFacets: [GroupedFacetInput!]!
    $promotionId: String
    $nbaAlgorithm: String
    $onlyPPCResults: Boolean
  ) {
    filters(
      searchTerm: $searchTerm
      department: $department
      experimentValues: $experimentValues
      filters: {
        groupedFacets: $groupedFacets
        promotionId: $promotionId
        onlyPPCResults: $onlyPPCResults
      }
      nbaAlgorithm: $nbaAlgorithm
    ) {
      facetsWithToggles {
        ...filterToggleFragment
        ...filterInvertedToggleFragment
        ...topLevelFacetFragment
      }
    }
  }

  fragment filterFacetFragment on FilterFacet {
    all
    count
    nbaScore
    __typename
    label
    facetKey
    group
    isSelected
    isQuickFilter
  }

  fragment filterToggleFragment on ToggleFilter {
    facetKey
    group
    label
    isSelected
    icon
  }

  fragment filterInvertedToggleFragment on InvertedToggleFilter {
    facetKey
    group
    label
    isSelected
    icon
  }

  fragment topLevelFacetFragment on FilterFacet {
    ...filterFacetFragment
    children {
      ...midLevelFacetFragment
      ...header
    }
    __typename
  }

  fragment midLevelFacetFragment on FilterFacet {
    ...filterFacetFragment
    children {
      ...bottomLevelFacetFragment
      ...header
    }
    __typename
  }

  fragment bottomLevelFacetFragment on FilterFacet {
    ...filterFacetFragment
    __typename
  }

  fragment header on FilterHeader {
    name
    __typename
  }
`

const loadFilters: (
  context: AppContext,
  requestContext: LoadFiltersRequestContext,
) => Promise<LoadFiltersResponse> = async (
  {
    features: { experimentValues, nbaAlgorithm },
    options: { payPerClickFiltering },
    client,
  },
  { searchTerm, facets, departments, promotionId },
) => {
  const result = await runGraphQLQuery<
    GetFiltersServiceQuery,
    GetFiltersServiceQueryVariables,
    LoadFiltersResponse
  >({
    client,
    query: GetFiltersServiceGQL,
    variables: {
      searchTerm,
      department: departments,
      experimentValues,
      groupedFacets: transformFiltersToGroupedFilters(facets),
      promotionId,
      nbaAlgorithm,
      onlyPPCResults: payPerClickFiltering,
    },
    extractResult: data => {
      const filters = (
        data?.filters.facetsWithToggles as FilterItemGqlType[]
      ).map(facet => {
        return mapGqlFilterItemToDomain(facet)
      })

      return {
        filters,
      }
    },
  })

  return result
}

export const createLoadFilters = (context: AppContext): LoadFiltersRequest => {
  return async requestContext => {
    return loadFilters(context, requestContext)
  }
}
